<template>
	<main class="container">
		<template v-if="loading">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</template>

		<div v-if="!loading">
			<div class="flex space-between mb-40">
				<div>
					<h1 v-if="!editing" class="mb-10">Stovna teldupost</h1>
					<h1 v-if="editing" class="mb-10">Rætta teldupost</h1>

					<p v-if="!editing" class="color-grey">
						Stovna ein teldupost, sum tú kanst senda frá einum leisti, umvegis ein kunda, einum terminali ella einum sáttmála.
					</p>
					<p v-if="editing" class="color-grey">{{ form.title }}</p>
				</div>
			</div>

			<div class="grid grid-3-5 grid-gap-20">
				<div>
					<div class="mb-30">
						<label class="block mb-10" for="currency">Slag av telduposti</label>

						<select class="v-select" v-model="form.type">
							<option value="Kunda">Kunda</option>

							<option value="Sáttmála">Sáttmála</option>

							<option value="Terminali">Terminali</option>
						</select>
					</div>

					<label for="message" class="mb-10">Kunning</label>

					<div class="actions">
						<div v-for="(placeholder, index) in placeholders" :key="index" class="action" :class="{ expanded: active === index }">
							<div class="action-title" @mousedown.prevent="toggle(index)">
								{{ placeholder.title }} <i class="fas fa-chevron-down fa-sm color-grey"></i>
							</div>

							<div class="action-names">
								<a
									href="#"
									v-for="(item, index) in placeholder.items"
									:key="index"
									@mousedown.prevent
									@click.prevent="
										if (item.disabled) {
											return;
										}

										insert(Object.keys(item)[0]);
									"
									class="action-name"
									:class="{ disabled: item.disabled }"
								>
									{{ Object.values(item)[0] }}
								</a>
							</div>
						</div>
					</div>
				</div>

				<form
					action="#"
					@submit.prevent="
						if (editing) {
							update();
						} else {
							save();
						}
					"
				>
					<div class="mb-20">
						<label for="title" class="mb-10">Heiti</label>

						<input v-model="form.title" @focus="disableAllExcept()" @blur="enableAllExcept()" id="title" data-model="title" type="text" />
					</div>

					<div class="mb-20">
						<label for="to" class="mb-10">Móttakari / Móttakarar</label>

						<input v-model="form.to" id="to" @focus="disableAllExcept('customerEmail')" @blur="enableAllExcept()" data-model="to" type="text" />
					</div>

					<div class="mb-20">
						<label for="cc" class="mb-10">CC</label>

						<input v-model="form.cc" id="cc" @focus="disableAllExcept('customerEmail')" @blur="enableAllExcept()" data-model="cc" type="text" />
					</div>

					<div class="mb-20">
						<label for="subject" class="mb-10">Evni</label>

						<input v-model="form.subject" id="subject" data-model="subject" type="text" />
					</div>

					<div class="mb-20">
						<label for="message" class="mb-10">Boð</label>

						<textarea v-model="form.message" data-model="message" cols="30" rows="10" class="mb-20 no-resize"></textarea>
					</div>

					<div class="buttons flex justify-end">
						<button href="#" class="btn" :class="{ disabled: $v.$invalid }">{{ editing ? 'Rætta teldupost' : 'Stovna teldupost' }}</button>
					</div>
				</form>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'Actions',

	data() {
		return {
			editing: false,
			loading: false,

			form: {
				title: '',
				to: '',
				cc: '',
				subject: '',
				message: '',
				type: 'Kunda',
			},
			active: null,
			placeholders: [
				{
					title: 'Kundi',
					items: [
						{
							customerRegistrationNumber: 'Skrásetingarnummar',
							disabled: false,
						},
						{
							customerName: 'Navn',
							disabled: false,
						},
						{
							customerVTal: 'V-tal',
							disabled: false,
						},
						{
							customerEmail: 'Teldupostur',
							disabled: false,
						},
						{
							customerPhoneNumber: 'Telefonnummar',
							disabled: false,
						},
						{
							customerAddress: 'Bústaður',
							disabled: false,
						},
						{
							customerZip: 'Postnummar',
							disabled: false,
						},
						{
							customerCity: 'Býur',
							disabled: false,
						},
						{
							customerMcc: 'MCC',
							disabled: false,
						},
						{
							customerTCSUsername: 'TCS brúkaranavn',
							disabled: false,
						},
						{
							customerTCSEmail: 'TCS teldubústaður',
							disabled: false,
						},
						{
							customerTCSPhone: 'TCS telefonnummar',
							disabled: false,
						},
					],
				},
				{
					title: 'Terminalur',
					items: [
						{
							terminalVerifoneNumber: 'Verifone nummar',
							disabled: false,
						},
						{
							terminalVerifoneSerialNumber: 'Verifone seriu nummar',
							disabled: false,
						},
						{
							terminalPhoneNumber: 'Telefonnummar',
							disabled: false,
						},
						{
							terminalPSAM: 'PSAM',
							disabled: false,
						},
						{
							terminalICC: 'ICC',
							disabled: false,
						},
						{
							terminalCradleNumber: 'Seriunummar á vøgguni',
							disabled: false,
						},
					],
				},
				{
					title: 'Sáttmáli',
					items: [
						{
							agreementType: 'Slag av sáttmála',
							disabled: false,
						},
						{
							agreementStepList: 'Leistur',
							disabled: false,
						},
						{
							agreementStartDate: 'Byrjunar dato',
							disabled: false,
						},
						{
							agreementEndDate: 'Enda dato',
							disabled: false,
						},
						{
							agreementTOF: 'TOF',
							disabled: false,
						},
						{
							agreementEcom: 'E-Com nummar',
							disabled: false,
						},
						{
							agreementPOS: 'POS nummar',
							disabled: false,
						},
						{
							agreementLink: 'Kunda Leinkja',
							disabled: false,
						},
						{
							agreementAccountNumber: 'Kontonummar',
							disabled: false,
						},
					],
				},
			],
		};
	},

	mounted() {
		if (!this.$route.params.id) {
			return;
		}

		this.loading = true;

		this.editing = true;

		axios
			.get(`${process.env.VUE_APP_TERMINAL_API_URL}/actions/${this.$route.params.id}`)
			.then((response) => {
				this.form.title = response.data.title;
				this.form.to = response.data.to;
				this.form.cc = response.data.cc;
				this.form.subject = response.data.subject;
				this.form.message = response.data.message;
				this.form.type = response.data.type;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		toggle(index) {
			if (this.active === index) {
				this.active = null;

				return;
			}

			this.active = index;
		},

		insert(string) {
			const element = document.activeElement;

			const selectionStart = element.selectionStart;
			const selectionEnd = element.selectionEnd;

			if (isNaN(selectionStart)) {
				return;
			}

			let value = element.value;

			string = `{${string}}`;

			value = value.slice(0, selectionStart) + string + value.slice(selectionEnd);

			element.value = value;

			element.setSelectionRange(selectionStart, selectionStart + string.length);

			if (element.dataset.model) {
				this.form[element.dataset.model] = value;
			}
		},

		disableAllExcept(names) {
			for (const placeholder of this.placeholders) {
				for (const item of placeholder.items) {
					if (names && names.includes(Object.keys(item)[0])) {
						continue;
					}

					item.disabled = true;
				}
			}
		},

		enableAllExcept(names) {
			for (const placeholder of this.placeholders) {
				for (const item of placeholder.items) {
					if (names && names.includes(Object.keys(item)[0])) {
						continue;
					}

					item.disabled = false;
				}
			}
		},

		save() {
			if (this.$v.$invalid) {
				return;
			}

			axios
				.post(`${process.env.VUE_APP_TERMINAL_API_URL}/actions`, {
					title: this.form.title,
					to: this.form.to,
					cc: this.form.cc,
					message: this.form.message,
					subject: this.form.subject,
					type: this.form.type,
				})
				.then(
					() => {
						this.$router.push({ name: 'Actions' });
					},
					(error) => {
						console.log('error', error);
					}
				);
		},

		update() {
			if (this.$v.$invalid) {
				return;
			}

			axios
				.patch(`${process.env.VUE_APP_TERMINAL_API_URL}/actions/${this.$route.params.id}`, {
					title: this.form.title,
					to: this.form.to,
					cc: this.form.cc,
					message: this.form.message,
					subject: this.form.subject,
					type: this.form.type,
				})
				.then(
					() => {
						this.$router.push({ name: 'Actions' });
					},
					(error) => {
						console.log('error', error);
					}
				);
		},
	},

	validations: {
		form: {
			title: { required },
			to: { required },
			subject: { required },
			message: { required },
		},
	},
};
</script>

<style lang="scss" scoped>
.message-groups {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.action {
	margin-bottom: 16px;

	&.expanded {
		.action-title i {
			transform: rotate(180deg);
		}

		.action-names {
			max-height: unset;
		}
	}

	.action-title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		padding: 18px;

		color: #383838;
		background: white;

		font-size: 1.2rem;

		border: 1px solid #e8e8e8;
		border-radius: 5px;

		transition: 0.1s background-color ease;
		cursor: pointer;

		&:hover {
			background: #f8f8f8;
		}

		i {
			transition: 0.15s transform ease;
		}
	}

	.action-names {
		max-height: 0;
		overflow: hidden;
	}

	.action-name {
		display: block;

		padding: 12px 18px;
		border-bottom: 1px solid #eaeaea;

		transition: 0.1s color ease-in-out;

		&.disabled {
			pointer-events: none;
			color: #e8e8e8;
		}

		&:hover {
			background: #fafafa;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}
}
</style>
